.popup-modal {
    .modal-content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .close-button {
            bottom: 10px;
            z-index: 1;
            background: transparent;
            border: none;
            color: #000;
            font-size: 16px;
        }

        .confirm-button {
            margin-top: 20px;
        }
    }
}
@primary-color: #B38922;@secondary-color: #199947;@content-background-color: #fff;@content-background-color-light: #FAFAFA;